document.addEventListener("DOMContentLoaded", (): void => {
  console.log("DOM loaded with JavaScript");
  // Get all "navbar-burger" elements
  const navbarBurgers: Element[] = Array.from(document.querySelectorAll(".navbar-burger"));

  // Add a click event on each of them
  navbarBurgers.forEach((navbarBurger: Element) => {
    navbarBurger.addEventListener("click", (): void => {
      console.log("navbar-burger clicked")

      // Get the target from the "data-target" attribute
      const targetId: string | null = navbarBurger.getAttribute("data-target");
      const targetElement: HTMLElement | null = document.getElementById(targetId || "");

      if (targetElement) {
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        navbarBurger.classList.toggle("is-active");
        targetElement.classList.toggle("is-active");
      }

    });
  });

});
